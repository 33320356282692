.container__packages {
    background-color: var(--clr-divider);
    height: auto;
    padding-top: 3.125rem;
    padding-bottom: 70px;
}


.heading__packages,
.breadcrumb-title__packages {
    text-transform: uppercase;
}


.title__packages {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.line__packages {
    width: 30%;
    margin: auto;
    border: 5px solid var(--clr-accent);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.title__packages {
    width: 35%;
    margin: auto;
}


/* 
--------------------------------------------
            Packages Styles
--------------------------------------------
*/

.sponsorship__packages {
    padding: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}


.card__packages {
    background-color: var(--clr-background);
    width: 300px;
    border-radius: 15px;
    height: auto;
    padding-top: 1.875rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-bottom: 100px;
}

.platinum {
    background-color: var(--clr-accent);
    color: var(--clr-background);
}

.platinum .line_card__packages {
    border: 2px solid var(--clr-background);
}

.line_card__packages {
    width: 40%;
    margin: auto;
    border: 2px solid var(--clr-accent);
    margin-top: 5px;
    margin-bottom: 10px;
}


.card_text__packages {
    font-size: var(--ui-type);
    line-height: 1.4;
    padding-top: 30px;
    padding-bottom: 50px;
}


.heading__card {
    text-align: center;
}


/* Buttons */


.button__packages {
    text-align: center;
}

.button-one {
    border-radius: .4375rem;
    padding: 10px 35px;
    background-color: var(--clr-primary-text);
    color: var(--clr-background);
}

.button-one:hover {
    border: 3px solid var(--clr-primary-text);
    background-color: transparent;
    color: var(--clr-primary-text);
    font-weight: 600;
}

.button-two {
    border-radius: .4375rem;
    padding: 10px 35px;
    background-color: var(--clr-accent);
    color: var(--clr-background);
}

.button-two:hover {
    border: 3px solid var(--clr-accent);
    background-color: transparent;
    color: var(--clr-accent);
    font-weight: 600;
}

.button-three {
    border-radius: .4375rem;
    padding: 10px 35px;
    background-color: var(--clr-background);
    color: var(--clr-accent);
}

.button-three:hover {
    border: 3px solid var(--clr-background);
    background-color: transparent;
    color: var(--clr-background);
    font-weight: 600;
}



@media screen and (max-width:1024px) {

    .sponsorship__packages {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .card__packages {
        background-color: var(--clr-background);
        width: 80%;
        border-radius: 15px;
        height: auto;
        padding-top: 1.875rem;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        padding-bottom: 100px;
    }

    .platinum {
        background-color: var(--clr-accent);
        color: var(--clr-background);
    }

}


@media screen and (max-width:450px) {
    .card__packages {
        width: 100%;
    }
}



@media screen and (max-width:378px) {
    .title__packages {
        width: 80%;
        margin: auto;
    }

    .card__packages {
        width: 100%;
    }

    .line__packages {
        width: 100px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}