.container__agenda {
    background-image: url('../../../public/images/agenda.jpg');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    z-index: 0;

    color: var(--clr-background);
    position: relative;
    height: auto;
    padding-top: 20px;
    padding-bottom: 120px;
}

.breadcrumb-title_agenda,
.section-title_agenda {
    text-transform: uppercase;
}

.row_section-title__agenda {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: auto;
    padding-top: 30px;
}

.section-title_agenda {
    margin-bottom: 20px;
}

.line__agenda {
    width: 20%;
    margin: auto;
    border: 5px solid var(--clr-accent);
    margin-top: 20px;
    margin-bottom: 20px;
}

.description__agenda {
    width: 80%;
    text-align: center;
}

.row_section_features__agenda {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 80%;
    margin: auto;
    padding: 15px;
    margin-top: 30px;
}


/* 
--------------------------------------
Card 
--------------------------------------
*/

.card_features__agenda {
    background-color: var(--clr-background);
    color: var(--clr-primary-text);
    width: 300px;
    height: auto;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.card_features__agenda img {
    width: 130px;
    height: auto;
}

.line_card_features__agenda {
    width: 40%;
    margin: auto;
    border: 2px solid var(--clr-accent);
    margin-top: 10px;
    margin-bottom: 10px;
}

.card_title {

    text-align: center;
}

.card_text {
    width: 70%;
    text-align: center;
}



/* 
--------------------------------------------
Media Queries
--------------------------------------------
*/

@media screen and (max-width: 1024px) {

    .section-title_agenda {
        margin-bottom: 10px;
        text-align: center;
    }

    .line__agenda {
        width: 30%;
    }


    .description__agenda {
        width: 80%;
        text-align: center;
    }

    .row_section_features__agenda {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
        width: 100%;
        margin: auto;
        padding: 15px;
        margin-top: 30px;
    }

    .card_features__agenda {
        width: 300px;
        height: auto;
    }
}



@media screen and (max-width: 600px) {

    .section-title_agenda {
        margin-bottom: 10px;
        text-align: center;
    }

    .line__agenda {
        width: 30%;
    }


    .description__agenda {
        width: 80%;
        text-align: center;
    }

    .row_section_features__agenda {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        margin: auto;
        padding: 15px;
        margin-top: 30px;
    }

    .card_features__agenda {
        width: 300px;
        height: auto;
    }
}



@media screen and (max-width: 375px) {

    .row_section-title__agenda {
        width: 80%;
    }

    .section-title_agenda {
        margin-bottom: 10px;
        text-align: center;
    }

    .line__agenda {
        width: 6.25rem;
        margin: auto;
        border: .1875rem solid var(--clr-accent);
    }


    .description__agenda {
        width: 100%;
        text-align: center;
    }

    .row_section_features__agenda {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin: auto;
        padding: 15px;
        margin-top: 30px;
    }

    .card_features__agenda {
        width: 300px;
        height: auto;
    }
}