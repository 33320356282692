.container__newsletter {
    display: flex;
    justify-content: center;
    gap: 40px;
    height: 100vh;
    background-color: var(--clr-primary-text);
}

.text__newsletter {
    flex: 1;
    height: 100%;
}

.text__newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--clr-background);
}

.title__newsletter {
    text-transform: uppercase;
}

.line_newsletter {
    width: 20%;
    margin: auto;
    border: 5px solid var(--clr-accent);
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
}

.description__newsletter {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/* 
-----------------------------------------
            FORM
-----------------------------------------
*/

.form__newsletter {
    flex: 1;
}

.form__newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.theForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 60px 30px;
    border-radius: 15px;
    background-color: var(--clr-accent);
}

.theForm input,
textarea {
    width: 90%;
    border-radius: 5px;
    border: transparent;
}


.button_subscribe {
    border-radius: .4375rem;
    padding: 5px 70px;
    background-color: var(--clr-primary-text);
    color: var(--clr-background);
}


.button_subscribe:hover {
    border: 3px solid var(--clr-primary-text);
    background-color: transparent;
    color: var(--clr-primary-text);
    font-weight: 600;
}


/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Newsletter Component CSS  */

@media screen and (max-width:1024px) {
    .container__newsletter {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding-top: 50px;
        padding-bottom: 100px;
    }

    .line_newsletter {
        width: 150px;
    }

    .description__newsletter {
        width: 40%;
        margin-top: 20px;
    }

    .theForm {
        width: 60%;
    }
}


@media screen and (max-width:540px) {
    .container__newsletter {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .line_newsletter {
        width: 150px;
    }

    .description__newsletter {
        width: 60%;
        margin-top: 20px;
    }

    .theForm {
        width: 80%;
        padding: 50px 30px;
    }
}

@media screen and (max-width:450px) {
    .container__newsletter {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .line_newsletter {
        width: 150px;
    }

    .description__newsletter {
        width: 60%;
        margin-top: 20px;
    }

    .theForm {
        width: 80%;
        padding: 50px 30px;
    }
}

@media screen and (max-width:375px) {
    .container__newsletter {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .line_newsletter {
        width: 150px;
    }

    .description__newsletter {
        width: 60%;
        margin-top: 20px;
    }

    .theForm {
        width: 80%;
        padding: 50px 30px;
    }
}

/* Ends - Media Queries - Newsletter Component CSS  */