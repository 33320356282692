@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&family=League+Gothic&family=League+Spartan:wght@100..900&display=swap');



/* Fonts */

.league-gothic-regular {
    font-family: "League Gothic", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
}

.league-spartan {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.archivo-narrow {
    font-family: "Archivo Narrow", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}


:root {

    /* Colors */
    --clr-primary: #bd9027;
    --clr-primary-dark: #6e634c;
    --clr-primary-light: #e1c143;
    --clr-accent: #E17243;
    --clr-background: #fefefe;
    --clr-primary-text: #353233;
    --clr-secondary-text: #757575;
    --clr-divider: #BDBDBD;
    --clr-divider-accent: #E17243;


    /* Types */
    --ff-body: "Archivo Narrow",
        sans-serif;
    --ff-title: "League Gothic",
        sans-serif;
    --ff-subtitle: "League Spartan",
        sans-serif;

    /* Font Sizes */
    /* Step -2: 12.5px → 12.8px */
    --small-type: clamp(0.7813rem, 0.7775rem + 0.0188vw, 0.8rem);
    /* Step -1: 15px → 16px */
    --ui-type: clamp(0.9375rem, 0.925rem + 0.0625vw, 1rem);
    /* Step 0: 18px → 20px */
    --body-type: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
    /* Step 1: 21.6px → 25px */
    --fs-subtitle: clamp(1.35rem, 1.3075rem + 0.2125vw, 1.5625rem);
    /* Step 2: 25.92px → 31.25px */
    --fs-2: clamp(1.62rem, 1.5534rem + 0.3331vw, 1.9531rem);
    /* Step 3: 31.104px → 39.0625px */
    --fs-3: clamp(1.944rem, 1.8445rem + 0.4974vw, 2.4414rem);
    /* Step 4: 37.3248px → 48.8281px */
    --fs-Heading-3: clamp(2.3328rem, 2.189rem + 0.719vw, 3.0518rem);
    /* Step 5: 44.7898px → 61.0352px */
    --fs-Heading-2: clamp(2.7994rem, 2.5963rem + 1.0153vw, 3.8147rem);
    /* Step 6: 53.7477px → 76.2939px */
    --fs-Heading-1: clamp(3.3592rem, 3.0774rem + 1.4091vw, 4.7684rem);
    /* Title: 64.4973px → 95.3674px */
    --fs-title-2: clamp(4.0311rem, 3.6452rem + 1.9294vw, 5.9605rem);
    /* Step 8: 77.3967px → 119.2093px */
    --fs-title-1: clamp(4.8373rem, 4.3146rem + 2.6133vw, 7.4506rem);
    /* Step 9: 92.876px → 149.0116px */
    --fs-title: clamp(5.8048rem, 5.1031rem + 3.5085vw, 9.3132rem);

    /* Spaces */
    /* Space 3xs: 5px → 5px */
    --space-3xs: clamp(0.3125rem, 0.3125rem + 0vw, 0.3125rem);
    /* Space 2xs: 9px → 10px */
    --space-2xs: clamp(0.5625rem, 0.55rem + 0.0625vw, 0.625rem);
    /* Space xs: 14px → 15px */
    --space-xs: clamp(0.875rem, 0.8625rem + 0.0625vw, 0.9375rem);
    /* Space s: 18px → 20px */
    --space-s: clamp(1.125rem, 1.1rem + 0.125vw, 1.25rem);
    /* Space m: 27px → 30px */
    --space-m: clamp(1.6875rem, 1.65rem + 0.1875vw, 1.875rem);
    /* Space l: 36px → 40px */
    --space-l: clamp(2.25rem, 2.2rem + 0.25vw, 2.5rem);
    /* Space xl: 54px → 60px */
    --space-xl: clamp(3.375rem, 3.3rem + 0.375vw, 3.75rem);
    /* Space 2xl: 72px → 80px */
    --space-2xl: clamp(4.5rem, 4.4rem + 0.5vw, 5rem);
    /* Space 3xl: 108px → 120px */
    --space-3xl: clamp(6.75rem, 6.6rem + 0.75vw, 7.5rem);

    /* One-up pairs */
    /* Space 3xs-2xs: 5px → 10px */
    --space-3xs-2xs: clamp(0.3125rem, 0.25rem + 0.3125vw, 0.625rem);
    /* Space 2xs-xs: 9px → 15px */
    --space-2xs-xs: clamp(0.5625rem, 0.4875rem + 0.375vw, 0.9375rem);
    /* Space xs-s: 14px → 20px */
    --space-xs-s: clamp(0.875rem, 0.8rem + 0.375vw, 1.25rem);
    /* Space s-m: 18px → 30px */
    --space-s-m: clamp(1.125rem, 0.975rem + 0.75vw, 1.875rem);
    /* Space m-l: 27px → 40px */
    --space-m-l: clamp(1.6875rem, 1.525rem + 0.8125vw, 2.5rem);
    /* Space l-xl: 36px → 60px */
    --space-l-xl: clamp(2.25rem, 1.95rem + 1.5vw, 3.75rem);
    /* Space xl-2xl: 54px → 80px */
    --space-xl-2xl: clamp(3.375rem, 3.05rem + 1.625vw, 5rem);
    /* Space 2xl-3xl: 72px → 120px */
    --space-2xl-3xl: clamp(4.5rem, 3.9rem + 3vw, 7.5rem);

    /* Custom pairs */
    /* Space s-3xl: 18px → 120px */
    --space-s-3xl: clamp(1.125rem, -0.15rem + 6.375vw, 7.5rem);


    /* letter spacing */

    --tracking: -0.05ch;
    --tracking-s: -0.075ch;
    --tracking-m: -0.1ch;
    --tracking-l: -0.125ch;
    --tracking-xl: -0.15ch;
    --tracking-2xl: -0.175ch;
    --tracking-3xl: -0.2ch;

    /* Image Size */

    --img-size-16-64: clamp(16px, -0.696px + 5.2174vi, 64px);
    --img-size-64-100: clamp(64px, 51.4784px + 3.913vi, 100px);
    --img-size-100-300: clamp(100px, 30.4352px + 21.7391vi, 300px);
    --img-size-300-500: clamp(300px, 230.4352px + 21.7391vi, 500px);
    --img-size-500-700: clamp(500px, 430.4352px + 21.7391vi, 700px);
    --img-size-700-1000: clamp(700px, 595.6528px + 32.6087vi, 1000px);

}