.container_SponsorForm {
    padding-top: 100px;
    padding-bottom: 100px;
}

.title__SponsorForm {
    width: 80%;
    margin-left: auto;
    margin-bottom: auto;
}

.line__SponsorForm {
    position: absolute;
    width: 225px;
    margin: auto;
    border: 2px solid var(--clr-accent);
    margin-top: .625rem;
    margin-bottom: 30px;
}

form {
    padding-top: 50px;
}

.form_SponsorForm {
    width: 80%;
    margin-left: auto;
    margin-bottom: auto;
}


.field__SponsorForm {
    width: 60%;
    border: 1px solid var(--clr-accent);
    border-radius: 5px;
}

/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Title Hero Component CSS  */
@media screen and (max-width:1024px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

@media screen and (max-width: 768px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

@media screen and (max-width:540px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

@media screen and (max-width:450px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

@media screen and (max-width:375px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

@media screen and (max-width:365px) {
    .container_SponsorForm {
        padding-top: 120px;
        padding-bottom: 70px;
    }

    .title__SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }

    .line__SponsorForm {
        width: 200px;
    }

    form {
        padding-top: 50px;
    }

    .form_SponsorForm {
        width: 90%;
        margin-left: auto;
        margin-bottom: auto;
    }


    .field__SponsorForm {
        width: 90%;
    }
}

/* Ends - Media Queries - Title Hero Component CSS  */