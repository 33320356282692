/* 
-------------------------------------
            Buttons
-------------------------------------
*/

.button {
    color: var(--clr-background);
    background-color: var(--clr-primary-text);
    border-radius: .4375rem;
    padding: 10px 35px;
}

.button:hover {
    border: 3px solid var(--clr-primary-text);
    background-color: transparent;
    color: var(--clr-primary-text);
    font-weight: 600;
}


/* 
-------------------------------------
            Lines
-------------------------------------
*/

/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Title Hero Component CSS  */
@media screen and (max-width:1024px) {}

@media screen and (max-width:378px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {}

@media screen and (max-width:378px) {}

/* Ends - Media Queries - Title Hero Component CSS  */