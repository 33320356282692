.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.4rem 6.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.125rem);
  z-index: -1;
}

.header::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent);
  transition: 0.5s;
}

.header:hover::after {
  left: 100%;
}

.logo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.logo img {
  width: var(--img-size-16-64);
}

@media (max-width:992px) {
  .logo img {
    width: var(--img-size-64-100);
  }
}


.navbar {
  flex: 2.2;
}

.navbar a {
  position: relative;
  font-size: var(--ui-type);
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  margin-left: 2.5rem;
}

.navbar a::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0.125rem;
  background: var(--clr-primary-text);
  transition: 0.3s;
}

.navbar a:hover::before {
  width: 100%;
}

#check {
  display: none;
}

.icons {
  position: absolute;
  right: 5%;
  font-size: 2.8rem;
  color: var(--clr-primary-text);
  cursor: pointer;
  display: none;
}

/* BREAKPOINTS */
@media (max-width: 992px) {
  .header {
    padding: 1.3rem 5%;
  }
}

@media (max-width: 768px) {
  .navbar a::before {
    display: none;
  }

  .icons {
    display: inline-flex;
  }

  #check:checked~.icons #menu-icon {
    display: none;
  }

  #check:checked~.icons #close-icon {
    display: block;
  }

  .icons #close-icon {
    display: none;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.125rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, rgba(0, 0, 0, 0.1));
    overflow: hidden;
    transition: 0.3s ease;
  }

  #check:checked~.navbar {
    height: 17.7rem;
  }

  .navbar a {
    display: block;
    font-size: 1.1rem;
    margin: 1.5rem 0;
    text-align: center;
    transform: translateY(-3.125rem);
    opacity: 0;
    transition: 0.3s ease;
  }

  #check:checked~.navbar a {
    transform: translateY(0);
    opacity: 1;
    transition-delay: calc(0.15s * var(--i));
  }
}