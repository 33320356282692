.container__aboutUs {
    width: 40%;
    height: auto;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
}

main {
    background-color: var(--clr-background);
}

.row__title__aboutUs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.line__aboutUs {
    width: 150px;
    border: 3px solid var(--clr-accent);
    margin-top: 20px;
    margin-bottom: 20px;
}

.text__aboutUS {
    padding-top: 50px;
}

/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - About Us Page CSS  */
@media screen and (max-width:1024px) {
    .container__aboutUs {
        width: 50%;
        height: auto;
        padding-top: 120px;
        padding-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:768px) {
    .container__aboutUs {
        width: 60%;
        height: auto;
        padding-top: 120px;
        padding-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:540px) {
    .container__aboutUs {
        width: 60%;
        height: auto;
        padding-top: 120px;
        padding-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:450px) {
    .container__aboutUs {
        width: 70%;
        height: auto;
        padding-top: 100px;
        padding-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width:375px) {
    .container__aboutUs {
        width: 70%;
        height: auto;
        padding-top: 100px;
        padding-bottom: 80px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Ends - Media Queries - About Us Page CSS  */