  /* 
  ------------------------------------
  Partner Title
  ------------------------------------
  */

  .container__partners {
      height: auto;
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
  }

  .row_title_partners {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .breadcrumb-title__partners {
      text-transform: uppercase;
  }

  .line__partners {
      width: 12%;
      margin: auto;
      border: 5px solid var(--clr-accent);
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
  }

  .description__partners {
      width: 30%;
  }


  /*  
  -------------------------------------
  Cluster
  -------------------------------------
  */

  .switcher__partners {
      width: 60%;
      margin: auto;
      padding: 3.125rem;
  }

  .switcher__partners img {
      width: 20%;
      height: auto;
      background-color: #f5f4f41c;
  }

  .partners_box {
      display: flex;
      justify-content: center;
  }



  /* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

  /* Starts - Media Queries - Title Hero Component CSS  */
  @media screen and (max-width:1024px) {
      .description__partners {
          width: 80%;
      }

      .partners_box {
          display: flex;
          flex-direction: column;
          align-items: center;
      }

      .switcher__partners img {
          width: 70%;
          height: auto;
          background-color: #f5f4f41c;
      }
  }

  @media screen and (max-width:768px) {
      .description__partners {
          width: 80%;
      }

      .partners_box {
          display: flex;
          flex-direction: column;
          align-items: center;
      }

      .switcher__partners img {
          width: 80%;
          height: auto;
          background-color: #f5f4f41c;
      }
  }

  @media screen and (max-width:540px) {
      .description__partners {
          width: 80%;
      }

      .partners_box {
          display: flex;
          flex-direction: column;
      }

      .switcher__partners img {
          width: 90%;
          height: auto;
      }
  }

  @media screen and (max-width:450px) {
      .description__partners {
          width: 80%;
      }

      .switcher__partners {
          width: 90%;
          margin: auto;
          padding-top: 10px;
          padding-bottom: 10px;
      }

      .line__partners {
          width: 6.25rem;
          margin-top: 30px;
          margin-bottom: 30px;
          border: .1875rem solid var(--clr-accent);
      }

      .partners_box {
          display: flex;
          flex-direction: column;
      }

      .switcher__partners img {
          width: 100%;
          height: auto;
          background-color: #f5f4f41c;
      }
  }

  @media screen and (max-width:378px) {

      .container__partners {
          padding-top: 30px;
      }

      .switcher__partners {
          width: 90%;
          margin: auto;
          padding-top: 10px;
          padding-bottom: 10px;
      }

      .partners_box {
          display: flex;
          flex-direction: column;
      }

      .line__partners {
          width: 6.25rem;
          margin-top: 30px;
          margin-bottom: 30px;
          border: .1875rem solid var(--clr-accent);
      }

      .description__partners {
          width: 80%;
      }


      .switcher__partners img {
          width: 90%;
          height: auto;
          background-color: #f5f4f41c;
      }
  }

  /* Ends - Media Queries - Title Hero Component CSS  */