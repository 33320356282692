/* 
-------------------------------------
            Footer Component CSS
-------------------------------------
*/

.container__footer {
    background-color: var(--clr-primary-text);
    color: var(--clr-background);
    padding-top: 50px;
    height: auto;
}

.row__footer {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.navbar__footer {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.logo__footer img {
    width: 80px;
    height: auto;
}

.links__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.links__footer a {
    text-decoration: none;
}

.links__footer a:hover {
    text-decoration: none;
}

.base__footer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-size: var(--ui-type);
}

.base__footer p {
    padding-top: 20px;
    padding-bottom: 20px;
}

.line__footer {
    border: 2px solid var(--clr-accent);
}

.icons__footer img {
    width: 32px;
    height: auto;
}

.icons__footer {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries Footer Component CSS */
@media screen and (max-width:1024px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {
    .container__footer {
        padding-top: 10px;
        padding-bottom: 4.375rem;
        height: auto;
    }

    .navbar__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.25rem;
    }

    .links__footer {
        display: flex;
        flex-direction: column;
        gap: .3125rem;
    }

    .base__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

@media screen and (max-width:375px) {
    .container__footer {
        padding-top: 10px;
        padding-bottom: 4.375rem;
        height: auto;
    }

    .navbar__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.25rem;
    }

    .links__footer {
        display: flex;
        flex-direction: column;
        gap: .3125rem;
    }

    .base__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
}

/* Ends - Media Queries Footer Component CSS */