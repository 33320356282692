/* 
-------------------------------------
        Title Hero Component CSS
-------------------------------------
*/

.container_title-hero {
    position: relative;
    width: 100%;
    height: 100vh;
}


.overlay_title-hero {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: var(--clr-accent);
    z-index: 2;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

}

.text_container_title-hero {
    position: absolute;
    z-index: 3;
    top: 23%;
    width: 100%;
    text-align: center;
    height: auto;
}

.main-title_hero-title,
.breadcrumb__hero-title,
.subtitle_hero {
    text-transform: uppercase;
}


.container_title-hero img {
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    object-fit: cover;
    object-position: center;
}

.button_title-hero {
    margin-top: 30px;
}


/* 
-------------------------------------
            Lines
-------------------------------------
*/

.line_hero-title {
    width: 20%;
    margin: auto;
    border: .3125rem solid var(--clr-primary-text);
    margin-top: 10px;
    margin-bottom: 10px;
}


/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Title Hero Component CSS  */
@media screen and (max-width:1024px) {
    .text_container_title-hero {
        position: absolute;
        z-index: 3;
        top: 30%;
        width: 100%;
        text-align: center;
        height: auto;
    }

    .line_hero-title {
        width: 35%;
    }
}


@media screen and (max-width:540px) {
    .text_container_title-hero {
        position: absolute;
        z-index: 3;
        top: 25%;
        width: 100%;
        text-align: center;
        height: auto;
    }

    .line_hero-title {
        width: 55%;
    }

    .main-title_hero-title h1 {
        line-height: 5rem;
    }
}

@media screen and (max-width:450px) {
    .main-title_hero-title h1 {
        line-height: 5rem;
    }

    .line_hero-title {
        width: 70%;
    }
}

@media screen and (max-width:375px) {

    .container_title-hero {
        width: 100%;
        height: auto;
    }

    .main-title_hero-title h1 {
        line-height: 5rem;
    }

    .line_hero-title {
        width: 70%;
    }
}

/* Ends - Media Queries - Title Hero Component CSS  */