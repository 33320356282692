/* 
--------------------------------------------
      Embedded Styling Elements
--------------------------------------------
*/

@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400..700;1,400..700&family=League+Gothic&family=League+Spartan:wght@100..900&display=swap');
@import './reset.css';
@import './variables.css';
@import './styles.css';



/* 
---------------------------------------------
      Body Global Styles 
---------------------------------------------
*/

body {
  background-color: var(--clr-background);
  color: var(--clr-primary-text);
  font-family: var(--ff-body);
  font-size: var(--body-type);
  font-weight: 400;
  letter-spacing: var(--tracking-m);
  line-height: 2rem;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  
----------------------------------------------
      Headings Global Styles
----------------------------------------------
*/

h1,
h2 {
  color: inherit;
  padding-top: var(--space-2xs);
  padding-bottom: var(--space-2xs);
}

h3,
h4,
h5,
h6 {
  padding-top: var(--space-3xs);
  padding-bottom: var(--space-3xs);
}

h1,
h2 {
  font-family: var(--ff-title);
  letter-spacing: var(--tracking);

}

h1 {
  font-size: var(--fs-title-2);
  line-height: 6rem;
}

h2 {
  font-size: var(--fs-Heading-2);
  line-height: 3.5rem;
}


h3 {
  font-family: var(--ff-subtitle);
  font-size: var(--fs-subtitle);
  font-weight: 550;
  line-height: 2rem;
}

h4,
h5,
h6 {
  font-family: var(--ff-subtitle);
}


/* 
--------------------------------------
      line lengths 
--------------------------------------
*/
p,
li,
blockquote:not([class]) {
  /* max-width: 50ch; */
}

h1,
h2,
h3 {
  /* max-width: 20ch; */
}

/* 
--------------------------------------
      Base interactive elements 
--------------------------------------
*/
a {
  color: inherit;
  cursor: pointer;
  font-size: var(--ui-type);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: var(--clr-accent);
}

:focus {
  outline: 1px solid;
  outline-offset: 0.3ch;
}

:target {
  scroll-margin-top: 2ex;
}


/* 
-----------------------------------------------------------------
Base selection styles that invert whatever colors are rendered
-----------------------------------------------------------------
*/
::selection {
  background: var(--clr-accent);
  color: var(--clr-background);
}


/* 
--------------------------------------
      Utilities CSS Styles 
--------------------------------------
*/

/* 
FLOW UTILITY
Like the Every Layout stack: https://every-layout.dev/layouts/stack/
Info about this implementation: https://piccalil.li/quick-tip/flow-utility/ 
*/
.flow>*+* {
  margin-top: var(--flow-space, 1em);
}

/**
 * REGION 
 * Add consistent vertical padding to create regions of content 
 * Can either be configured by setting --region-space or use a default from the space scale
 */
.region {
  padding-top: var(--region-space, var(--space-l-xl));
  padding-bottom: var(--region-space, var(--space-l-xl));
}

/**
 * WRAPPER
 * Sets a max width, adds a consistent gutter and horizontally
 * centers the contents
 * Info: https://piccalil.li/quick-tip/use-css-clamp-to-create-a-more-flexible-wrapper-utility/
 */
.wrapper {
  max-width: var(--wrapper-max-width, 85rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative;
}