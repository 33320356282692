.speakers {
    height: 100vh;
    position: relative;
    color: var(--clr-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.speakers img {
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: -3;
}

.overlay__speakers {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: var(--clr-primary-text);
    z-index: -2;
    opacity: 0.9;
}

.breadcrumb-title_speakers,
.section-title_speakers {
    text-transform: uppercase;
}


.breadcrumb-title_speakers,
.section-title_speakers {
    width: 40%;
}

.description_speakers {
    width: 30%;
}

.line__register {
    width: 12%;
    margin: auto;
    border: 1px solid var(--clr-accent);
    margin-top: 20px;
    margin-bottom: 20px;
}

.cta_register {
    padding-top: 30px;
}

.button_register {
    border-radius: .4375rem;
    padding: 10px 35px;
    background-color: var(--clr-accent);
    color: var(--clr-background);
    text-transform: uppercase;
}

.button_register:hover {
    border: 3px solid var(--clr-accent);
    background-color: transparent;
    color: var(--clr-accent);
    font-weight: 600;
}


/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Title Hero Component CSS  */
@media screen and (max-width:1024px) {

    .breadcrumb-title_speakers,
    .section-title_speakers {
        width: 60%;
    }

    .description_speakers {
        width: 60%;
    }

    .line__register {
        width: 80px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 920px) {

    .breadcrumb-title_speakers,
    .section-title_speakers {
        width: 60%;
    }

    .description_speakers {
        width: 60%;
    }

    .line__register {
        width: 80px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:540px) {

    .breadcrumb-title_speakers,
    .section-title_speakers {
        width: 70%;
    }

    .description_speakers {
        width: 70%;
    }

    .line__register {
        width: 80px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:450px) {

    .breadcrumb-title_speakers,
    .section-title_speakers {
        width: 70%;
    }

    .description_speakers {
        width: 70%;
    }

    .line__register {
        width: 80px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:378px) {

    .breadcrumb-title_speakers,
    .section-title_speakers {
        width: 70%;
    }

    .description_speakers {
        width: 70%;
    }

    .line__register {
        width: 80px;
        margin: auto;
        border: 3px solid var(--clr-accent);
        margin-top: 20px;
        margin-bottom: 20px;
    }

}

/* Ends - Media Queries - Title Hero Component CSS  */