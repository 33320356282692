/* 
-------------------------------------
        About AMC Component CSS
-------------------------------------
*/

.container__about-amc {
    position: relative;
    width: 100%;
    height: auto;
}

.container__about-amc img {
    width: 100%;
    height: auto;
    opacity: 0.8;
    object-fit: cover;
    object-position: center;
}

.text_row__about-amc {
    position: absolute;
    top: 5%;
    left: 50%;
    right: 20%;
    height: auto;
    margin: auto;
}

.breadcrumb-title__about-amc {
    text-transform: uppercase;
}

.section-title__about-amc {
    margin-bottom: .625rem;
    text-transform: uppercase;
}

.section-subtitle__about-amc {
    margin-top: 3.125rem;
}

.description__about-amc {
    margin-top: 30px;
    margin-bottom: 30px;
}



/* 
-------------------------------------
            Lines
-------------------------------------
*/


.line_about-amc {
    position: absolute;
    width: 30%;
    margin: auto;
    border: .3125rem solid var(--clr-accent);
    margin-top: .625rem;
    margin-bottom: 1.25rem;
}


/* Starts - Media Queries About AMC Component CSS */

@media screen and (max-width:1368px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 950px;
    }

    .text_row__about-amc {
        position: absolute;
        top: 10%;
        /* left: 30%; */
        right: 15%;
        margin: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 59.375rem;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: .625rem;
        margin-bottom: 4.375rem;
    }
}

@media screen and (max-width:1024px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 59.375rem;
    }

    .text_row__about-amc {
        position: absolute;
        top: 10%;
        left: 30%;
        right: 30%;
        margin: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 700px;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: .625rem;
        margin-bottom: 4.375rem;
    }
}

@media screen and (max-width:768px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 59.375rem;
    }

    .text_row__about-amc {
        position: absolute;
        top: 10%;
        left: 20%;
        right: 20%;
        margin-left: auto;
        margin-right: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 37.5rem;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: .625rem;
        margin-bottom: 4.375rem;
    }

}

@media screen and (max-width:540px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 71.875rem;
        padding-bottom: 6.25rem;
    }

    .text_row__about-amc {
        position: absolute;
        top: 10%;
        left: 20%;
        right: 20%;
        margin: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 100vh;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: 1.875rem;
        margin-bottom: 4.375rem;
    }

}

@media screen and (max-width:450px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 81.25rem;
        padding-bottom: 6.25rem;
    }

    .text_row__about-amc {
        position: absolute;
        top: 8%;
        left: 20%;
        right: 20%;
        margin: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 100vh;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-bottom: 1.875rem;
    }

    .description__about-amc {
        margin-top: 1.875rem;
        margin-bottom: 4.375rem;
    }
}

@media screen and (max-width:376px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 1150px;
        padding-bottom: 100px;
    }

    .text_row__about-amc {
        position: absolute;
        top: 10%;
        left: 12%;
        right: 18%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
    }

    .line_about-amc {
        width: 6.25rem;
        margin: auto;
        border: .1875rem solid var(--clr-accent);
    }

    .container__about-amc img {
        width: 100%;
        height: auto;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: 30px;
        margin-bottom: 60px;
    }
}


@media screen and (max-width:365px) {
    .container__about-amc {
        position: relative;
        width: 100%;
        height: 1100px;
        padding-bottom: 100px;
    }

    .text_row__about-amc {
        position: absolute;
        top: 5%;
        left: 12%;
        right: 18%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        height: auto;
    }

    .container__about-amc img {
        width: 100%;
        height: 700px;
        opacity: 0.8;
        object-fit: cover;
        object-position: center;
    }

    .description__about-amc {
        margin-top: 30px;
        margin-bottom: 60px;
    }
}

/* Ends - Media Queries About AMC Component CSS */