.container__TicketForm {
  padding-top: 80px;
  padding-bottom: 50px;
}

.row_title__TicketForm {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.title__TicketForm {
  text-transform: uppercase;
}

.line__TicketForm {
  border: 2px solid var(--clr-accent);
  width: 200px;
}

.title__TicketForm {
  margin-bottom: 15px;
}

.form__TicketForm {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}


.field__ticketForm {
  width: 90%;
  border-radius: 5px;
  border: 1px solid var(--clr-accent);
}


/* 
-------------------------------------
        Media Queries Section
-------------------------------------
*/

/* Starts - Media Queries - Ticket Form CSS  */
@media screen and (max-width:1024px) {
  .container__TicketForm {
    padding-top: 200px;
    padding-bottom: 50px;
  }

  .row_title__TicketForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding-bottom: 100px;
  }

  .field__ticketForm {
    width: 95%;
    border-radius: 5px;
  }
}

@media screen and (max-width:768px) {
  .row_title__TicketForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .field__ticketForm {
    width: 95%;
    border-radius: 5px;
  }
}

@media screen and (max-width:540px) {
  .row_title__TicketForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .field__ticketForm {
    width: 95%;
    border-radius: 5px;
  }
}

@media screen and (max-width:450px) {
  .row_title__TicketForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .field__ticketForm {
    width: 95%;
    border-radius: 5px;
  }
}

@media screen and (max-width:378px) {
  .row_title__TicketForm {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .field__ticketForm {
    width: 90%;
    border-radius: 5px;
  }
}

@media screen and (max-width:350px) {
  .row_title__TicketForm {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .form__TicketForm {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .field__ticketForm {
    width: 90%;
    border-radius: 5px;
  }
}

/* Ends - Media Queries - Ticket Form CSS  */